import React, { useState, useEffect } from 'react';

const DiscountModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    service: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  const toggleModal = () => {
    if (isOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, 300);
    } else {
      setIsOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (value.length <= 10 && /^[0-9]*$/.test(value)) {
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
      setError(""); // Clear error if input is valid
    } else {
      setError("Phone number must be exactly 10 digits.");
    }
  };

  // Send data to backend API
  const sendToBackendAPI = async () => {
    try {
      const response = await fetch('https://api.myvisapoint.com/api/contact/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });

      if (!response.ok) {
        throw new Error('Failed to send message to backend');
      }
      return response;
    } catch (error) {
      throw new Error('Failed to send message to backend');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccess('');

    try {
      const backendResponse = await sendToBackendAPI();

      if (backendResponse.ok) {
        setSuccess('Message sent successfully!');
        setForm({ name: '', email: '', phone: '', message: '', service: '' });
      } else {
        throw new Error('Failed to send the message.');
      }
    } catch (err) {
      setError('Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      {/* Floating Message Icon */}
      <div
        className={`fixed right-6 cursor-pointer bg-sky-500 text-white p-3 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110 ${isOpen ? 'bottom-96' : 'bottom-6'}`}
        onClick={toggleModal}
      >
        <i className="fa-solid fa-envelope fa-2xl"></i>
      </div>

      {/* Modal */}
      {isOpen && (
        <div
          className={`fixed inset-0 flex items-center justify-center z-[9999] bg-black bg-opacity-50 transition-all duration-500 ${isClosing ? 'opacity-0' : 'opacity-100'}`}
        >
          <div
            className="bg-gradient-to-br from-sky-500 to-emerald-500 rounded-lg shadow-lg p-10 max-w-xl w-full transform transition-transform duration-500"
          >
            <div className="flex justify-between items-center">
              <h2 className="font-semibold text-white text-xl sm:text-2xl md:text-2xl lg:text-3xl">
                Book An Appointment
              </h2>
              <button onClick={toggleModal} className="text-white text-3xl hover:text-gray-300 ">
                &times;
              </button>
            </div>

            <p className="text-white text-start text-sm my-2 mb-6 ">
              Contact us now for Visa & Immigration services!
            </p>

            {/* Form */}
            <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg p-6 rounded-lg shadow-inner">
              <form onSubmit={handleSubmit}>
                <label className="block mb-4">
                  <input
                    type="text"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    className="w-full p-3 rounded-full border border-transparent bg-white bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-orange-300 shadow-sm"
                    required
                  />
                </label>
                <label className="block mb-4">
                  <input
                    type="email"
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    className="w-full p-3 rounded-full border border-transparent bg-white bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-orange-300 shadow-sm"
                    required
                  />
                </label>

                {/* Phone Number Input */}
                <label className="block mb-4">
                  <input
                    type="tel"
                    name="phone"
                    value={form.phone}
                    onChange={handleChange}
                    placeholder="Your Phone Number"
                    className="w-full p-3 rounded-full border border-transparent bg-white bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-orange-300 shadow-sm"
                    required
                  />
                </label>


                {/* Service Dropdown */}
                <label className="block mb-4">
                  <select
                    name="service"
                    value={form.service}
                    onChange={handleChange}
                    className="w-full p-3 rounded-full border border-transparent bg-white bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-orange-300 shadow-sm"
                    required
                  >
                    <option value="">Select Service</option>
                    <option value="tourist">Tourist Visa</option>
                    <option value="study">Study Visa</option>
                    <option value="super">Super Visa</option>
                    <option value="Interview Preparation">Interview Preparation</option>
                    <option value="immigration">Immigration Visa</option>
                    <option value="IELTS/PTE">IELTS/PTE</option>
                  </select>
                </label>

                {success && <p className="text-green-600 font-semibold text-center bg-green-100 p-2 rounded-md my-4">{success}</p>}
                {error && (
                  <p className="text-red-600 font-semibold text-center bg-red-100 p-2 rounded-md my-4">
                    {error}
                  </p>
                )}


                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-sky-600 text-white py-3 rounded-full hover:bg-sky-800 transition duration-200"
                >
                  {isSubmitting ? 'Sending...' : 'Send Message'}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscountModal;
