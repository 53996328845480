import React from 'react';
import {Helmet} from "react-helmet";

const NewZealand = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-sky-500 to-emerald-500 ... text-white text-center py-12">
        <h1 className="text-5xl font-extrabold">Study and Travel in New Zealand</h1>
        <p className="mt-4 text-lg">Discover the vibrant educational landscape and breathtaking travel experiences that New Zealand has to offer! At My Visa Point, we specialize in guiding you through the visa application process, whether you're seeking to study or explore this beautiful country.</p>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-8">
        {/* Study Visa Section */}
        <section id="study-visa" className="my-8">
          <h2 className="text-3xl font-extrabold text-sky-800 mb-6 flex items-center transition-transform transform hover:scale-105">
            <img src="https://img.icons8.com/ios/50/000000/student-center.png" alt="Study Icon" className="w-12 h-12 mr-3" />
            New Zealand Study Visa -  Elevate Your Education
          </h2>
          <div className="bg-white shadow-xl rounded-lg p-8 mb-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
            <p className="text-gray-700 mb-4">
            New Zealand boasts a government-funded education system that emphasizes a comprehensive and well-rounded curriculum. From academic studies to sports and vocational training, students benefit from a supportive environment that fosters personal growth. Most schools are equippedwith excellent facilities, including playing fields, gymnasiums, and swimming pools, enhancing the overall learning experience.
            </p>
          </div>

          <div className="bg-white shadow-xl rounded-lg p-8 mb-6 flex flex-col md:flex-row items-center justify-between transition-transform transform hover:scale-105 hover:shadow-2xl">
            <div className="md:w-2/3 w-full pr-4">
              <h3 className="text-xl font-semibold text-sky-600 mb-3">A High-Quality Education</h3>
              <p className="text-gray-700 mb-4">
              Our team  is dedicated to assisting you in your study visa application. We help you navigate university and course selection, ensuring you make informed choices tailored to your aspirations. With relatively low tuition costs and the opportunity for part-time work, studying in New Zealand is an accessible and enriching experience.

              </p>
              {/* <p className="text-gray-700">
                Study expenses are comparatively low, and students can benefit from part-time job opportunities during their education.
              </p> */}
            </div>
            <img 
              src="https://ik.imagekit.io/6oa6qejxe/brooke-cagle-g1Kr4Ozfoac-unsplash.jpg?updatedAt=1725533654037" 
              alt="Education" 
              className="w-full md:w-1/4 h-40 object-cover mt-6 md:mt-0"
            />
          </div>
        </section>

        {/* Tourist Visa Section */}
        <section id="tourist-visa" className="my-8">
          <h2 className="text-3xl font-extrabold text-sky-800 mb-6 flex items-center transition-transform transform hover:scale-105">
            <img src="https://img.icons8.com/ios/50/000000/passport.png" alt="Tourist Visa Icon" className="w-12 h-12 mr-3" />
            New Zealand Tourist Visa
          </h2>
          <div className="bg-white shadow-xl rounded-lg p-8 mb-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
            <p className="text-gray-700 mb-4">
            The New Zealand Tourist Visa is designed for those wishing to explore the country for leisure, visit friends or family, or partake in short-term courses.
            </p>
            <h3 className="text-xl font-semibold text-sky-600 mb-3">Who Needs a Tourist Visa?</h3>
            <p className="text-gray-700 mb-4">
              - Citizens from visa waiver countries can enjoy up to three months of visa-free travel.
              - Citizens from non-visa waiver countries must apply for a Visitor Visa.
            </p>
            <h3 className="text-xl font-semibold text-sky-600 mb-3 mt-6">Application Process</h3>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
              <li>Check Eligibility</li>
              <li>Complete Online Application</li>
              <li>Gather the necessary Documents</li>
              <li>Pay applicable Fees</li>
              <li>Submit Application</li>
              <li>Attend Biometrics Appointment (if required)</li>
            </ul>
          </div>
        </section>

        {/* Permanent Residency Section */}
        <section id="pr" className="my-8">
          <h2 className="text-3xl font-extrabold text-sky-800 mb-6 flex items-center transition-transform transform hover:scale-105">
            <i className="fas fa-house mr-3"></i>
            New Zealand Permanent Residency
          </h2>
          <div className="bg-white shadow-xl rounded-lg p-8 mb-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
            <p className="text-gray-700 mb-4">
              New Zealand offers various pathways for individuals and familiesseeking permanent residency (PR).
            </p>
            <h3 className="text-xl font-semibold text-sky-600 mb-3">Pathways to PR</h3>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
              <li>Skilled Migrant Category (SMC)</li>
              <li>Family Sponsorship</li>
              <li>Business and Investor Immigration</li>
              <li>Long-Term Skill Shortage List (LTSSL)</li>
            </ul>
            <h3 className="text-xl font-semibold text-sky-600 mb-3 mt-6">Application Process</h3>
            <p className="text-gray-700">
            The PR application involves selecting a pathway, gathering documents, completing the online application, and attending any required medical or biometric appointments.
            </p>
          </div>
        </section>

        {/* Spouse Open Work Permit Section */}
        <section id="sowp" className="my-8">
          <h2 className="text-3xl font-extrabold text-sky-800 mb-6 flex items-center transition-transform transform hover:scale-105">
            <img src="https://img.icons8.com/ios/50/000000/heart-with-arrow.png" alt="Spouse Visa Icon" className="w-12 h-12 mr-3" />
            New Zealand Spouse Visa  – Reunite with Your Loved One
          </h2>
          <div className="bg-white shadow-xl rounded-lg p-8 transition-transform transform hover:scale-105 hover:shadow-2xl">
            <p className="text-gray-700 mb-4">
            The New Zealand Spouse Visa allows partners of New Zealand citizens or residents to live together as a family.
            </p>
            <h3 className="text-xl font-semibold text-sky-600 mb-3">Our Services</h3>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
              <li>Initial Assessment of your situation</li>
              <li> Comprehensive Application Submission</li>
              <li> Ongoing Communication and Follow-up</li>
              <li>Interview Preparation</li>
              <li> Support Post-Approval </li>
            </ul>
            <p className="text-gray-700 mt-4">
            Contact us today to schedule a consultation and take the first step towards your journey in New Zealand. Let My Visa point help you make your dreams a reality!

            </p>
          </div>
        </section>
      </div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>New Zealand Visa Consultants in Chandigarh | My Visa Point</title>
                <meta name="description" content=" Looking to apply for a New Zealand visa? My Visa Point in Chandigarh offers expert guidance and support for a smooth application process. Contact us today!

" />
                <link rel="canonical" href="https://myvisapoint.com/newzealand" />
            </Helmet>
    </div>
  );
}

export default NewZealand;
