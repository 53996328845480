import React from "react";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

const USA = () => {
  const { ref: headerRef, inView: headerInView } = useInView({
    triggerOnce: true,
  });
  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: true,
  });
  const { ref: textRef, inView: textInView } = useInView({ triggerOnce: true });
  const { ref: ctaRef, inView: ctaInView } = useInView({ triggerOnce: true });

  return (
    <div className="bg-white min-h-screen">
      {/* Hero Section */}
      <div className="w-full bg-gradient-to-r from-sky-500 to-emerald-500 text-white text-center py-8 md:py-12">
        <h1 className="text-3xl md:text-5xl font-extrabold">
          USA Tourist and Study Visa
        </h1>
        <p className="mt-4 text-sm md:text-lg max-w-6xl mx-auto text-justify">
          The United States is a leading destination for both education and
          tourism, known for its prestigious universities and globally
          recognized landmarks. At MyVisa Point, we offer expert assistance in
          securing USA Tourist and Study Visas, making the application process
          smooth and stress-free. Whether you're aiming to pursue your studies
          at top American institutions or explore the country's iconic
          attractions, our team is here to guide you through every step.
        </p>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-6 md:py-8">
        {/* Study Visa Section */}
        <section id="study-visa" className="w-full my-6 md:my-8">
  <h2 className="text-2xl md:text-3xl font-extrabold text-sky-800 mb-4 md:mb-6 flex items-center transition-transform transform hover:scale-105">
    USA Study Visa (F-1 Visa) Eligibility Criteria
  </h2>
  <div className="w-full mx-auto items-center mb-8 md:mb-16">
    <div className="bg-white w-full shadow-xl rounded-lg p-6 md:p-8 mb-6 lg:mb-0 transition-transform transform hover:scale-105 hover:shadow-2xl border-l-4 md:border-l-8 border-sky-800">
      <p className="text-gray-700 mb-4">
        The F-1 Study Visa allows international students to attend academic programs at accredited U.S. institutions. To qualify for the F-1 Visa, you must meet the following requirements:
      </p>
      <ul className="list-disc list-inside text-gray-700 space-y-4">
        <li><strong>Admission to a SEVP-Approved Institution</strong>: The institution must be SEVP approved.</li>
        <li><strong>Form I-20</strong>: Confirms your acceptance into the program and provides details about your course of study and financial requirements.</li>
        <li><strong>Proof of Financial Support</strong>: Show that you have enough funds to cover tuition fees, living expenses, and other related costs while studying in the U.S. This may include bank statements, scholarship letters, or a financial sponsor's affidavit.</li>
        <li><strong>Academic Credentials</strong>: Provide evidence of your academic qualifications, such as transcripts, degrees, or diplomas from previous institutions.</li>
        <li><strong>English Proficiency</strong>: Submit TOEFL, IELTS, or other standardized test scores unless your program is in a different language.</li>
        <li><strong>Non-Immigrant Intent</strong>: Demonstrate that you intend to return to your home country after completing your studies.</li>
        <li><strong>Valid Passport</strong>: Ensure it is active for at least 5-6 months beyond your intended stay.</li>
        <li><strong>Consular Interview</strong>: A U.S. Embassy or Consulate will conduct an interview to assess your eligibility.</li>
      </ul>
    </div>
  </div>
</section>


        {/* Tourist Visa Section */}
        <section id="tourist-visa" className="my-6 md:my-8">
          <h2 className="text-2xl md:text-3xl font-extrabold text-sky-800 mb-4 md:mb-6 flex items-center transition-transform transform hover:scale-105">
            <img
              src="https://img.icons8.com/ios/50/000000/passport.png"
              alt="Tourist Visa Icon"
              className="w-10 h-10 md:w-12 md:h-12 mr-2 md:mr-3"
            />
            USA Tourist Visa - B-2 Visa
          </h2>
          <div className="bg-white shadow-xl rounded-lg p-6 md:p-8 transition-transform transform hover:scale-105 hover:shadow-2xl border-l-4 md:border-l-8 border-sky-700">
            <p className="text-gray-700 mb-4">
              The B-1/B-2 Tourist Visa is for individuals wishing to visit the
              United States temporarily for business (B-1) or tourism (B-2). To
              qualify for a Tourist Visa, you must meet the following criteria:
            </p>
            <h3 className="text-lg md:text-xl font-semibold text-sky-700 mb-2 md:mb-3">
              Purpose of Visit
            </h3>
            {/* <p className="text-gray-700 mb-4">
              - Visa Waiver Program (VWP) Countries...
              {/* Additional information */}
            {/* </p> */}

            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>
                B-1 Visa: You are visiting for business purposes such as
                attending meetings, conferences, or negotiations.
              </li>
              <li>
                B-2 Visa: You are visiting for tourism, vacation, medical
                treatment, or family visits. 2 Financial Proof
              </li>

              {/* Additional steps */}
            </ul>
            <h3 className="text-lg md:text-xl font-semibold text-sky-700 mb-2 md:mb-3 mt-4 md:mt-6">
              Financial Proof
            </h3>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>
                You must show that you have enough financial resources to cover
                all expenses related to your trip, including travel, lodging,
                and living costs.
              </li>

              {/* Additional requirements */}
            </ul>
            <h3 className="text-lg md:text-xl font-semibold text-sky-700 mb-2 md:mb-3 mt-4 md:mt-6">
              Proof of Intent to Return
            </h3>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>
                You need to provide proof of significant connections to your
                home country, such as a stable job, property, or family, to
                confirm your intention to return after your visit.
              </li>

              {/* Additional requirements */}
            </ul>
            <h3 className="text-lg md:text-xl font-semibold text-sky-700 mb-2 md:mb-3 mt-4 md:mt-6">
              No Immigrant Intent
            </h3>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>
                You must prove that your stay in the U.S. is temporary and that
                you do not have the intention to immigrate
              </li>

              {/* Additional requirements */}
            </ul>
            <h3 className="text-lg md:text-xl font-semibold text-sky-700 mb-2 md:mb-3 mt-4 md:mt-6">
              No Criminal Record
            </h3>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>
                You must not have any criminal convictions or immigration
                violations that would prevent you from entering the U.S.
              </li>

              {/* Additional requirements */}
            </ul>

            <h3 className="text-lg md:text-xl font-semibold text-sky-700 mb-2 md:mb-3 mt-4 md:mt-6">
              Consular Interview
            </h3>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>
                Embassy or Consulate will take your interview to demonstrate
                your eligibility and you answer questions about your travel
                plans.
              </li>

              {/* Additional requirements */}
            </ul>

            <h3 className="text-lg md:text-xl font-semibold text-sky-700 mb-2 md:mb-3 mt-4 md:mt-6">
              valid passport
            </h3>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>it should be active for a minimum of 5-6 months</li>

              {/* Additional requirements */}
            </ul>
          </div>
        </section>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>USA Visa Services in Chandigarh | My Visa Point</title>
        <meta
          name="description"
          content="Looking to apply for a USA visa? My Visa Point in Chandigarh offers expert guidance and support for all types of USA visa applications. Start your journey today! 



"
        />
        <link rel="canonical" href="https://myvisapoint.com/usa" />
      </Helmet>
    </div>
  );
};

export default USA;
