import React from "react";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

const StudyVisa = () => {
  const { ref: headerRef, inView: headerInView } = useInView({
    triggerOnce: true,
  });
  const { ref: textRef, inView: textInView } = useInView({ triggerOnce: true });
  const { ref: ctaRef, inView: ctaInView } = useInView({ triggerOnce: true });
  const { ref: contactRef, inView: contactInView } = useInView({
    triggerOnce: true,
  });

  return (
    <section className="bg-gray-100 py-16 px-8">
      {/* Section Header */}
      <div
        ref={headerRef}
        className={`text-center mb-16 ${headerInView ? "fade-in" : "opacity-0"
          }`}
      >
        <h2 className="text-5xl font-bold text-sky-600 mb-4">
          Your Pathway to Global Education
        </h2>
        <p className="text-lg text-gray-600 mb-6">
        My Visa Point is recognized as the leading study visa consultants in Chandigarh. We provide expert guidance and comprehensive support to students aiming to study abroad. Understanding the complexities of the visa application process, we are committed to making the entire experience seamless and hassle-free for our clients
        </p>
        {/* Add a relevant header image */}
        <img
          src="https://images.pexels.com/photos/1106468/pexels-photo-1106468.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt="Study Visa"
          className="mx-auto w-3/4 md:w-1/2 rounded-lg shadow-lg"
        />
      </div>

      {/* Section Content */}
      <div
        ref={textRef}
        className={`space-y-8 ${textInView ? "fade-in" : "opacity-0 translate-y-10"
          }`}
      >
        <div className="bg-white p-8 rounded-lg shadow-lg transition-transform duration-500 hover:shadow-xl">
          <h3 className="text-2xl font-bold text-sky-500 mb-4">
          Benefits of Applying for a Study Visa Through my visa point

          </h3>
          <div className="flex flex-col md:flex-row items-center">
            {/* Image for Benefits section */}
            <img
              src="https://images.pexels.com/photos/1462630/pexels-photo-1462630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="Study Visa Benefits"
              className="w-full md:w-1/3 rounded-lg shadow-lg mb-6 md:mb-0 md:mr-6"
            />
            <ul className="list-disc list-inside text-gray-600 space-y-4">
              <li>
                <strong>Consultation and Assessment:</strong> Our experienced consultants provide a detailed assessment of your academic background and career objectives, helping you choose the right study program and country that align with your goals.
              </li>
              <li>
                <strong>Documentation Assistance:</strong> Navigating the documentation process can be challenging. Our team ensures that all your paperwork is complete, accurate, and submitted on time, minimizing the chances of delays or rejections.

              </li>
              <li>
                <strong>Interview Preparation:</strong> For those required to attend visa interviews, we offer mock interview sessions to build your confidence and prepare you for success.

              </li>
              <li>
                <strong>PR Consulting:</strong> In addition to study visa services, we also provide consultation on permanent residency (PR) options for students who are looking to settle abroad after completing their education.

              </li>
              <li>
                <strong>Personalized Service:</strong> Each  student is special , and so are their specific requirements. Our team offers tailored solutions to suit your specific situation, ensuring a smooth application process.

              </li>
            </ul>
          </div>
        </div>

        <div className="bg-white p-8 rounded-lg shadow-lg transition-transform duration-500 hover:shadow-xl">
          {/* Additional image for visa consultants */}
          <div className="flex flex-col md:flex-row items-center">
            <p className="text-lg text-gray-600 mb-6">
            My Visa Point is recognized as the leading study visa consultants in Chandigarh. We provide expert guidance and comprehensive support to students aiming to study abroad. Understanding the complexities of the visa application process, we are committed to making the entire experience seamless and hassle-free for our clients

           <br />
           <br />
          
Our dedicated team of study visa consultants in Chandigarh offers personalized services for students applying to countries like the UK, Canada, Australia, and more. We focus on understanding your needs and providing guidance every step of the way.

            </p>
            
            <img
              src="https://images.pexels.com/photos/1595385/pexels-photo-1595385.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt="Visa Consultants"
              className="w-full md:w-1/3 rounded-lg shadow-lg"
            />
          </div>
          <p className="text-lg text-gray-600 mt-4">
          If you're ready to take the next step toward your international education, contact My Visa Point today. Let the best study visa consultants in Chandigarh help you make your dream of studying abroad a reality.
          </p>
        </div>

        <div className="bg-white p-8 rounded-lg shadow-lg transition-transform duration-500 hover:shadow-xl">
          <h3 className="text-2xl font-bold text-sky-500 mb-4">
            Countries We Support
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Simplifying country links by removing images */}
            <a
              href="/USA"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-sky-500 transition duration-300"
            >
              USA
            </a>
            <a
              href="/Uk"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-sky-500 transition duration-300"
            >
              United Kingdom
            </a>
            <a
              href="/Canada"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-sky-500 transition duration-300"
            >
              Canada
            </a>
            <a
              href="/Australia"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-sky-500 transition duration-300"
            >
              Australia
            </a>
            <a
              href="/NewZealand"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-sky-500 transition duration-300"
            >
             New Zealand
            </a>
            <a
              href="/schengen"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-sky-500 transition duration-300"
            >
              Schengen
            </a>
          </div>
        </div>
      </div>

      {/* Call-to-Action */}
      <div
        ref={ctaRef}
        className={`text-center mt-16 ${ctaInView ? "fade-in" : "opacity-0 translate-y-10"
          }`}
      >
        <a
          href="/ContactUs"
          className="px-8 py-4 bg-gradient-to-r from-sky-500 to-emerald-500 ...  text-white text-lg font-semibold rounded-lg shadow-lg hover:bg-teal-700 transition-colors duration-300"
        >
          Get Our Support
        </a>
      </div>

      {/* Helmet SEO */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Best Study Visa Consultants in Chandigarh | My Visa Point
        </title>
        <meta
          name="description"
          content="Looking for study visa consultants in Chandigarh? My Visa Point provides expert guidance for your study abroad journey. Start your application today!"
        />
        <link rel="canonical" href="https://myvisapoint.com/study-visa" />
      </Helmet>
    </section>
  );
};

export default StudyVisa;
