import React from "react";
import { Helmet } from "react-helmet";

const InterviewPreparation = () => {
  return (
    <section className="bg-gray-100 py-16 px-8">
      {/* Heading for the Interview Preparation Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-sky-600">
          Interview Preparation
        </h1>
      </div>

      {/* First Section: Image on Left, Content on Right */}
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center mb-16">
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/6567.jpg?updatedAt=1726567174384"
            alt="Top-Up Degree"
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
          />
        </div>
        <div className="lg:w-1/2 lg:pl-8">
          {/* <p  className="text-2xl text-black mb-6"> Crack Your Visa Interview In The First  Attempt with My Visapoint!</p> */}

          <p className="text-lg text-gray-700 mb-6">
            Securing a visa, job, or admission abroad often involves acing an
            interview. At MyVisa Point, we provide expert interview preparation
            services to help you confidently present yourself and succeed in
            interviews, whether for visa applications, university admissions, or
            job opportunities abroad. Our customized coaching is crafted to
            provide you with the tools, techniques, and confidence required to
            leave a lasting impression.
          </p>
        </div>
      </div>

      {/* Second Section: Content on Left, Image on Right */}
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 lg:pr-8">
          <div className="bg-white p-8 rounded-lg shadow-lg mb-8 lg:mb-0">
            <h6 className="text-2xl font-semibold text-sky-500 mb-4">
              Types of Interview Preparation We Offer
            </h6>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
              <li>Visa Interview Preparation.</li>
              <li>
                Student Visa Interviews: We help you prepare for questions
                related to your academic background, study plans, and intentions
                to return to your home country after completing your education..
              </li>
              <li>
                Work Visa Interviews: Our coaches guide you through common
                questions about your job qualifications, skills, and employer
                expectations..
              </li>
              <li>
                Immigration Visa Interviews: Learn how to confidently address
                questions about your background, family, and reasons for
                immigrating..
              </li>
            </ul>
          </div>
        </div>
        <div className="lg:w-1/2">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/4691.jpg?updatedAt=1726567163088"
            alt="Top-Up Degree"
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
          />
        </div>
      </div>
      <div className="bg-white max-w-6xl mx-auto p-8 rounded-lg shadow-lg mb-8 my-8 lg:mb-0">
          <h6 className="text-2xl font-semibold text-sky-500 mb-4">
            Why Choose Our Interview Preparation Services?
          </h6>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>
              Customized Coaching: We tailor our interview preparation sessions
              to suit the type of interview you're facing, whether it's for a
              visa, job, or academic program.
            </li>
            <li>
              Experienced Trainers: Our trainers have extensive experience in
              interview coaching, ensuring you receive valuable insights and
              practical advice on how to handle interview questions effectively.
            </li>
            <li>
              Mock Interviews: We conduct mock interviews that simulate
              real-world scenarios, helping you practice and refine your
              responses in a stress-free environment.
            </li>
            <li>
              Feedback & Improvement: After each session, we provide detailed
              feedback on your performance, focusing on areas that need
              improvement such as communication skills, body language, and
              clarity of responses
            </li>
          </ul>
        </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>IELTS & PTE Coaching in Chandigarh | My Visa Point</title>
        <meta
          name="description"
          content=" IELTS and PTE exams with expert coaching at My Visa Point, Chandigarh. Join us for tailored classes and achieve your study abroad goals today!

"
        />
        <link rel="canonical" href="https://myvisapoint.com/Interview" />
      </Helmet>
    </section>
  );
};

export default InterviewPreparation;
